import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { ToolboxPage, Tool } from '@drodil/backstage-plugin-toolbox';

import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderPage } from './components/scaffolder/ScaffolderPage';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { settingsPage } from './components/user-settings/SettingsPage';
import { apis, customGithubApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightIcon from '@material-ui/icons/WbSunny';
import { SecOpsComplianceWidget } from '@internal/plugin-secops-compliance';
import { OverviewPage } from '@internal/backstage-plugin-overview';
import { RagModal } from '@internal/plugin-rag-ai';
import { MermaidEditorToolbox } from './components/toolbox/MermaidEditorToolbox';
import { theme } from './theme';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';

const extraToolMermaidEditor: Tool = {
  id: 'mermaid-diagram',
  name: 'Mermaid Diagram',
  component: <MermaidEditorToolbox />,
};

const ACCELERATOR_RADARS = [
  {
    pathSuffix: 'data',
    title: 'Data engineer / Data scientist Tech Radar',
    subtitle:
      'Technologies we use and recommend for building data driven services within the Accelerator.',
    id: 'data-radar',
  },
  {
    pathSuffix: 'engineering',
    title: 'Engineering Tech Radar',
    subtitle:
      'This radar is a list of technologies we use and recommend for building services within the Accelerator.',
    id: 'engineering-radar',
  },
  {
    pathSuffix: 'cloud-saas',
    title: 'Cloud and SaaS Tech Radar',
    subtitle:
      '3rd party and internally supported products we use across the Accelerator. This includes cloud services, SaaS products, and other tools.',
    id: 'cloud-saas-radar',
  },
];

const app = createApp({
  apis,
  featureFlags: [
    {
      pluginId: '',
      name: 'dora-metrics-config-v2',
      description:
        'Whether to use the v1 or v2 version of the Dora metrics configuration',
    },
  ],
  themes: [
    {
      id: 'sanofi-light',
      title: 'Sanofi Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={theme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        providers={[
          {
            id: 'github-auth-provider',
            title: 'GitHub',
            message: 'Sign in using GitHub',
            apiRef: customGithubApiRef,
          },
        ]}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const storedPreferredTeam = localStorage.getItem('preferredTeam');

const splitString = (input: string | null) => {
  if (input === null) return { type: '', namespace: '', name: '' };
  const [type, rest] = input.split(':');
  const [namespace, name] = rest.split('/');
  return { type, namespace, name };
};

const teamObject = splitString(storedPreferredTeam);

const routes = (
  <FlatRoutes>
    <Route
      path="/"
      element={
        teamObject.type === 'user' || teamObject.type === '' ? (
          <HomepageCompositionRoot />
        ) : (
          <Navigate
            to={`/catalog/${teamObject.namespace}/group/${teamObject.name}`}
          />
        )
      }
    >
      {(teamObject.type === 'user' || teamObject.type === '') && <HomePage />}
    </Route>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <Mermaid />
      </TechDocsAddons>
    </Route>
    <Route path="/scaffolder" element={ScaffolderPage} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    {ACCELERATOR_RADARS.map(radar => {
      return (
        <Route
          key={radar.id}
          path={`radar/${radar.pathSuffix}`}
          element={
            <TechRadarPage
              title={radar.title}
              subtitle={radar.subtitle}
              width={1600}
              height={700}
              id={radar.id}
            />
          }
        />
      );
    })}
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      {settingsPage}
    </Route>
    <Route
      path="/toolbox"
      element={<ToolboxPage extraTools={[extraToolMermaidEditor]} />}
    />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/security" element={<SecOpsComplianceWidget />} />
    <Route path="/overview" element={<OverviewPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <RagModal />
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
