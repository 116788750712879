import {
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';

export const theme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#23004C',
    },
    secondary: {
      main: '#565a6e',
    },
    navigation: {
      background: '#000000',
      indicator: '#7a00e6',
      color: '#d5d6db',
      selectedColor: '#ffffff',
    },
  },
  defaultPageTheme: 'default',
  pageTheme: {
    default: genPageTheme({
      colors: ['#23004C', '#7A00E6'],
      shape: shapes.wave,
    }),
  },
});
