import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { myPluginApiRef } from '../../api';
import { useEntity } from '@backstage/plugin-catalog-react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorPanel, InfoCard } from '@backstage/core-components';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { useAsync } from 'react-use';
import { MonitoringSummary } from '@internal/plugin-plt-monitoring';

const supportedResources = [
  'apiGateway',
  'cloudFormation',
  'cloudfront',
  'dynamoDB',
  'lambda',
  's3Bucket',
  'rds',
  'ec2',
];

const parseRawData = (
  allResources: MonitoringSummary,
  teamNames: string[],
): AwsNumberResources => {
  const baseAWSResources: AwsNumberResources = {
    date: allResources.date,
    total: 0,
    resources: {},
  };

  const initialValue = {
    totalOk: 0,
    totalError: 0,
    teams: [],
  };
  for (const keyName of supportedResources) {
    baseAWSResources.resources[keyName] = structuredClone(initialValue);
  }

  teamNames.forEach(teamName => {
    const resultForTeam = allResources.tagged[teamName];
    if (!resultForTeam) {
      return;
    }

    Object.keys(resultForTeam).forEach(resourceName => {
      const errorsCount = resultForTeam[resourceName]?.error?.length || 0;
      const passedCount = resultForTeam[resourceName]?.passed?.length || 0;
      baseAWSResources.total += errorsCount + passedCount;

      baseAWSResources.resources[resourceName].totalOk += passedCount;
      baseAWSResources.resources[resourceName].totalError += errorsCount;
      baseAWSResources.resources[resourceName].teams.push({
        name: teamName,
        totalOk: passedCount,
        totalError: errorsCount,
      });
    });
  });

  return baseAWSResources;
};

export const SummaryResourceForTeam = () => {
  const [resourceList, setResourceList] = useState<AwsNumberResources | null>(
    null,
  );
  const myPluginApi = useApi(myPluginApiRef);
  const { entity } = useEntity();

  const getSummaryMetrics = async () => {
    const metrics = await myPluginApi.getSummary();
    const tags: string[] = [entity.metadata.name];
    entity.relations?.forEach((teamRelation: any) => {
      if (teamRelation.type === 'parentOf') {
        tags.push(teamRelation.target.name);
      }
    });
    const awsNumberResources = parseRawData(metrics, tags);
    setResourceList(awsNumberResources);
  };

  const { error, loading } = useAsync(async () => {
    await getSummaryMetrics();
  }, []);

  if (error) {
    return (
      <InfoCard title="AWS Resources" subheader="Error loading resources">
        <ErrorPanel error={error} defaultExpanded />
      </InfoCard>
    );
  }

  if (loading) {
    return (
      <InfoCard title="AWS Resources" subheader="Loading resources">
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="secondary" size={40} />
        </Box>
      </InfoCard>
    );
  }

  const getTooltipByResource = (resourceName: string) => {
    return resourceList &&
      (resourceList.resources[resourceName].totalOk > 0 ||
        resourceList.resources[resourceName].totalError > 0)
      ? resourceList.resources[resourceName].teams?.map(
          (teamResult: TeamResult) => (
            <p key={resourceName + teamResult.name}>
              {teamResult.name.toUpperCase()} :<br />- OK:&nbsp;
              {teamResult.totalOk} / Error:&nbsp;
              {teamResult.totalError}
            </p>
          ),
        )
      : `No ${resourceName} for ${entity.metadata.name.toUpperCase()}`;
  };

  const getIcon = (name: string) => {
    const baseCdnPath =
      'https://cdn.prod.accelerator.sanofi/backstage/awsIcons/';
    const iconName =
      name.toLowerCase() === 's3'
        ? 's3bucket.svg'
        : `${name.toLowerCase()}.svg`;
    const defaultSrc = `${baseCdnPath}aws.png`;

    return (
      <img
        width="24px"
        height="24px"
        src={`${baseCdnPath}${iconName}`}
        alt={name}
        onError={e => {
          (e.target as HTMLImageElement).src = defaultSrc;
        }}
      />
    );
  };

  const displayResources = () => {
    if (!resourceList?.resources) {
      return error;
    }
    return Object.keys(resourceList.resources).map(resourceName => {
      return (
        <Grid key={resourceName} item xs={12}>
          <Tooltip
            arrow
            placement="right"
            title={<div>{getTooltipByResource(resourceName)}</div>}
          >
            <Box
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex">
                {getIcon(resourceName)}
                <Typography variant="caption">
                  &nbsp;&nbsp;{resourceName}
                </Typography>
              </Box>
              <Box
                flexGrow="1"
                textAlign="end"
                marginLeft="5px"
                marginRight="15px"
              >
                <Typography variant="caption" color="primary">
                  {resourceList.resources[resourceName]?.totalOk}
                </Typography>
                {' / '}
                <Typography variant="caption" color="error">
                  {resourceList.resources[resourceName]?.totalError}
                </Typography>
              </Box>
              <Box width="30px" textAlign="center" fontWeight="bold">
                {resourceList.resources[resourceName]?.totalOk +
                  resourceList.resources[resourceName]?.totalError}
              </Box>
            </Box>
          </Tooltip>
        </Grid>
      );
    });
  };

  return (
    <InfoCard
      title="AWS Resources"
      subheader={`Total resources: ${resourceList?.total || 0}`}
      deepLink={{
        title: 'View full inventory data',
        link: `/catalog/default/Group/${entity.metadata.name}/aws-inventory`,
      }}
    >
      <Grid container spacing={2}>
        {loading && <CircularProgress size={40} />}
        {resourceList?.resources && displayResources()}
      </Grid>
    </InfoCard>
  );
};

type TeamResult = {
  name: string;
  totalOk: number;
  totalError: number;
};

type AwsNumberResources = {
  date: string;
  total: number;
  resources: any;
};
